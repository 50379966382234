$MAIN_COLOR: #00ce7c;
$MAIN_COLOR_OPACITY: #aee6cf;
$SECONDARY_COLOR: #000000;
$SECONDARY_COLOR_OPACITY: #000000;
$TERTIARY_COLOR: #bfd730;
$MENU_ITEM_ACTIVE: #00ce7c;
$MENU_BORDER: #ff8200;
$BACKGROUND_COLOR: #ffffff;
$ERROR_RED: #ed1c24;
$BLACK_TEXT_COLOR: #000000;
$GRAY_TEXT_COLOR: #8a8a8a;
$SECTION_COLOR: #243138;
$MENU_ICON_ACTIVE: #000000;
$MOBILE_MENU_OPEN_ICON_COLOR: #000000;
$MENU_CLOSE_ICON_COLOR: #000000;
$GRAY: #d9d9d9;
$GRAY_BORDER: 1px solid #808080;
$DISABLED_GRAY: #dbdbdb;

:export {
  MAIN_COLOR: $MAIN_COLOR;
  MAIN_COLOR_OPACITY: $MAIN_COLOR_OPACITY;
  SECONDARY_COLOR: $SECONDARY_COLOR;
  TERTIARY_COLOR: $TERTIARY_COLOR;
  MENU_ITEM_ACTIVE: $MENU_ITEM_ACTIVE;
  MENU_BORDER: $MENU_BORDER;
  BACKGROUND_COLOR: $BACKGROUND_COLOR;
  ERROR_RED: $ERROR_RED;
  SECONDARY_COLOR_OPACITY: $SECONDARY_COLOR_OPACITY;
  BLACK_TEXT_COLOR: $BLACK_TEXT_COLOR;
  GRAY_TEXT_COLOR: $GRAY_TEXT_COLOR;
  SECTION_COLOR: $SECTION_COLOR;
  MENU_ICON_ACTIVE: $MENU_ICON_ACTIVE;
  MENU_CLOSE_ICON_COLOR: $MENU_CLOSE_ICON_COLOR;
  DISABLED_GRAY: $DISABLED_GRAY;
  MOBILE_MENU_OPEN_ICON_COLOR: $MOBILE_MENU_OPEN_ICON_COLOR;
  GRAY_BORDER: $GRAY_BORDER;
}

body {
  --MAIN_COLOR: #{$MAIN_COLOR};
  --MAIN_COLOR_OPACITY: #{$MAIN_COLOR_OPACITY};
  --SECONDARY_COLOR: #{$SECONDARY_COLOR};
  --TERTIARY_COLOR: #{$TERTIARY_COLOR};
  --MENU_ITEM_ACTIVE: #{$MENU_ITEM_ACTIVE};
  --MENU_BORDER: #{$MENU_BORDER};
  --BACKGROUND_COLOR: #{$BACKGROUND_COLOR};
  --ERROR_RED: #{$ERROR_RED};
  --SECONDARY_COLOR_OPACITY: #{$SECONDARY_COLOR_OPACITY};
  --BLACK_TEXT_COLOR: #{$BLACK_TEXT_COLOR};
  --GRAY_TEXT_COLOR: #{$GRAY_TEXT_COLOR};
  --SECTION_COLOR: #{$SECTION_COLOR};
  --MENU_ICON_ACTIVE: #{$MENU_ICON_ACTIVE};
  --MENU_CLOSE_ICON_COLOR: #{$MENU_CLOSE_ICON_COLOR};
  --DISABLED_GRAY: #{$DISABLED_GRAY};
  --MOBILE_MENU_OPEN_ICON_COLOR: #{$MOBILE_MENU_OPEN_ICON_COLOR};
  --GRAY_BORDER: #{$GRAY_BORDER};
}
